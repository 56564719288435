<template>
    <div class="downloads content-wide" style="padding-top: 128px">
        <div class="content-img-container">
            <img src="@/assets/img/5.jpeg" :alt="$t('downloads.title')" />
        </div>

     
        <h1 class="big-title">{{ $t('downloads.title') }}</h1>
        <div class="downloads-content">
            <div>
                <div class="downloads-section">
                    <h3>{{ $t('downloads.logos') }}</h3>
                    <a href="https://rudetins.ch/download-files/rudetins-logo-eps.eps" download>EPS</a>
                    <a href="https://rudetins.ch/download-files/rudetins-logo-white.png" download>PNG (weiss)</a>
                    <a href="https://rudetins.ch/download-files/rudetins-logo-black.png" download>PNG (schwarz)</a>
                </div>
                <div class="downloads-section">
                    <h3>{{ $t('downloads.photo') }}</h3>
                    <a href="https://rudetins.ch/download-files/rudetins-foto.jpeg" download>JPEG</a>
                </div>
            </div>
            <div>
                <div class="downloads-section">
                    <h3>{{ $t('downloads.biography') }}</h3>
                    <a href="https://rudetins.ch/download-files/rudetins-bio.pdf" download>PDF</a>
                </div>
                <div class="downloads-section">
                    <h3>{{ $t('downloads.stagerider') }}</h3>
                    <a href="https://rudetins.ch/download-files/rudetins-stagerider-DE.pdf" download>PDF Deutsch</a>
                    <a href="https://rudetins.ch/download-files/rudetins-stagerider-EN.pdf" download>PDF English</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./Downloads.ts"></script>
<style lang="scss" scoped>
@import './Downloads.scss';
</style>
